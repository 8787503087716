<template>
  <div class="col-12 col-md-5 col-lg-5" id="video_container">
    <div id="videos">
      <div class="container">
        <div v-if="titleObject">
          <h1>{{ titleObject.title }}</h1>
          <p>
            {{ titleObject.description }}
          </p>
        </div>
        <div v-else>
          <h1>Feature Videos</h1>
          <p>Our Videos</p>
        </div>

        <iframe
          v-if="videos && videos.length > 0"
          allowfullscreen=""
          frameborder="0"
          width=""
          height=""
          :src="getEmbeddedUrl(videos[0].link)"
        ></iframe>

        <div
          class="d-flex p-3 videos_captions videos_captions_first"
          v-if="videos.length > 0"
        >
         
          <div class="px-2">
            <h5 class="mb-0 mt-1" style="cursor: pointer;" @click="navigateToWithParam('single_video','id',videos[0].id)">
              {{ videos[0].title }}
            </h5>
          </div>
        </div>
        <div
          class="d-flex p-3 videos_captions"
          v-for="(video, index) in videos.slice(1)"
          :key="index"
        >
          <div
            class="d-flex justify-content-center align-items-center"
          >
            <div class="d-flex pl-0">
              <div
                class="d-flex justify-content-center align-items-center"
              >
                <img class="home_video_thumbnail" :src="getThumbnailUrl(video.link)" :alt="video.title">
              </div>
            </div>
          </div>
          <div class="px-2">
            <h5 class="mb-0 mt-1" style="cursor: pointer;" @click="navigateToWithParam('single_video','id',video.id)">
              {{ video.title }}
            </h5>
          </div>
        </div>

        <button class="btn btn-primary" type="button" @click="navigateTo('videos')"> View All</button>
      </div>
    </div>

    <!-- <Loader :isLoading="isLoading" /> -->

  </div>
</template>

<script>
import GetVideos from "@/composables/GetVideos";
import { watchEffect } from "vue";
export default {
  name: "VideoComponent",
  data() {
    return {
      videos_error: null,
      // isLoading: false
    };
  },
  props: {
    titles: [],
  },
  mounted() {
    const { loadedVideos, videos_error, loadVideos } = GetVideos();
    watchEffect(() => {
      if (videos_error && videos_error.value) {
        this.videos_error = videos_error.value;
        // this.isLoading = false
      }
    });
    loadVideos(4);
  },
  computed: {
    titleObject() {
      if (this.titles) {
        return this.titles.find((title) => title.name === "videos_section");
      }
      return null;
    },
    videos(){
     return this.$store.getters.getVideos;
    }
  },
  methods: {
    
  },
};
</script>