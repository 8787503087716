<template>
  <NavComponent />
  <section id="About">
    <div class="colored_banner">
      <h1>IMS LEARN</h1>
      <!-- <p v-if="aboutData">
        {{ aboutData.header }}
      </p> -->
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
        <div class="col-md-6 col-lg-6 col-xs-12 p-0" v-if="aboutData">
          <div
            :style="{ backgroundImage: `url('${aboutData.image}')` }"
            id="about_img"
          ></div>
          <div id="about_info" class="p-4 p-sm-0" v-html="aboutData.text"></div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>

<script>
import { watchEffect } from "vue";
import GetAboutUs from "@/composables/GetAboutUs";
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";
export default {
  name: "AboutView",
  components: {
    NavComponent,
    FooterComponent,
  },
  data() {
    return {
      data_error: null,
    };
  },
  computed:{
    aboutData() {
      const about = this.$store.getters.getAboutUs;

      // Check if bannerArray is an array and has at least one element
      if (Array.isArray(about) && about.length > 0) {
        this.isLoading = false
        return about[0];
      } else {
        this.isLoading = true
        return null; // or return a default value if you prefer
      }
    }
  },
  mounted() {
    const { loadedData, data_error, loadData } = GetAboutUs();
    watchEffect(() => {
      if (!this.isRefEmpty(data_error)) {
        this.data_error = data_error.value;
      }
    });
    loadData();
  },
};
</script>


