import { ref } from 'vue'
const GetOneVideo = () => {

  const loadedVideo = ref(null)
  const video_error = ref(null)

  const loadVideo = async (id) => {
    try {
      let data = await fetch('https://dashboard.imslearn.org/api/videos?id='+id)
      if (!data.ok) {
        throw Error('This video is not available anymore')
      }
      await data.json().then((res) => {
        if (res.success) {
          loadedVideo.value = res.data
        }else{
          console.log('unable to load data for videos')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      video_error.value = err.message
    }
  }

  return { loadedVideo, video_error, loadVideo }
}

export default GetOneVideo