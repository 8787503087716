<template>
  <NavComponent />
  <section id="About">
    <div class="colored_banner">
      <h1 v-if="videoObj">{{ videoObj.title }}</h1>
      <h1 v-else>IMS LEARN</h1>
     
    </div>
    <div class="container">
      <div v-if="videoObj" class="row">
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
        <div class="col-md-6 col-lg-6 col-xs-12 p-0">
          <div
            id="video_info"
            class="p-4 p-sm-0"
            v-html="videoObj.content"
          ></div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-xs-12"></div>
        <div class="col-md-6 col-lg-8 col-xs-12 p-0" v-if="videoObj">
          <iframe
            v-if="videoObj && videoObj.link"
            allowfullscreen=""
            frameborder="0"
            width=""
            height=""
            :src="getEmbeddedUrl(videoObj.link)"
          ></iframe>
        </div>
        <div class="col-md-3 col-lg-2 col-xs-12"></div>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>
<script>
import { watchEffect } from "vue";
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";

import GetOneVideo from "@/composables/GetOneVideo";
export default {
  name: "VideoView",
  components: {
    NavComponent,
    FooterComponent,
  },
  data() {
    return {
      videoObj: null,
      videoId: null,
      video_error:null,
    };
  },
  computed: {},
  mounted() {
    this.videoId = JSON.parse(this.$route.query.id );
    const { loadedVideo, video_error, loadVideo } = GetOneVideo();
      watchEffect(() => {
        if (loadedVideo.value) {
          this.videoObj = loadedVideo.value;
          this.video_error = null;
        }else{
          console.log(loadVideo.value)
        }
        if (!this.isRefEmpty(video_error)) {
          this.video_error = video_error.value;
        }
      });
      loadVideo(this.videoId)
  },
};
</script>

<style scoped>
iframe{
  width: 100%;
    height: 500px;
    margin: 60px 0px;
}
</style>
