import { ref } from 'vue'
const GetOneTestimonial = () => {

  const loadedTestimonial = ref(null)
  const testimonial_error = ref(null)

  const loadTestimonial = async (id) => {
    try {
      let data = await fetch('https://dashboard.imslearn.org/api/data/testimonials?id='+id)
      if (!data.ok) {
        throw Error('This testimonial is not available anymore')
      }
      await data.json().then((res) => {
        if (res.success) {
          loadedTestimonial.value = res.data
        }else{
          console.log('unable to load data for testimonial')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      testimonial_error.value = err.message
    }
  }

  return { loadedTestimonial, testimonial_error, loadTestimonial }
}

export default GetOneTestimonial