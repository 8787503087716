<template>
  <section
    id="banner"
    class="py-4 py-xl-5"
    :style="{
      background: banner
        ? `linear-gradient(-99deg, #15616d 0, rgba(20, 33, 61, 0) 61%), url(${banner.image}) 50% /cover no-repeat, var(--primary)`
        : 'linear-gradient(-99deg, #15616d 0, rgba(20, 33, 61, 0) 61%)',
    }"
  >
    <div class="overlay"></div>
    <div
      class="container d-inline-flex flex-row flex-grow-1 justify-content-xl-end align-items-xl-end h-100"
    >
      <div class="row h-100">
        <div class="col-12 col-md-12 col-lg-6 offset-lg-6">
          <div v-if="banner" id="info">
            <h1>{{ banner.title }}</h1>
            <p class="mb-4">
              {{ banner.description }}
              <br /><br /><br />
            </p>
            <button class="btn btn-primary more" type="button"  @click="navigateTo('about')">
              Read More
            </button>
          </div>
        </div>
      </div>
    </div>

    <loader :isLoading="isLoading"/>
  </section>
</template>

<script>
import GetBanner from "@/composables/GetBanner";
import { watchEffect } from "vue";
export default {
  name: "BannerComponent",
  components: {},
  data() {
    return {
      loadedBanner: null,
      banner_error: null,
      bannerImg: null,
      isLoading: false
    };
  },
  computed:{
    banner() {
      const bannerArray = this.$store.getters.getBanner;

      // Check if bannerArray is an array and has at least one element
      if (Array.isArray(bannerArray) && bannerArray.length > 0) {
        this.isLoading = false
        return bannerArray[0];
      } else {
        this.isLoading = true
        return null; // or return a default value if you prefer
      }
    }
  },
  mounted() {
    const { loadedBanner, banner_error, loadBanner } = GetBanner();
    watchEffect(() => {
      if (!this.isRefEmpty(banner_error)) {
        this.banner_error = banner_error.value;
      }
     
    });
    loadBanner();
  },
  methods: {
    getBackgroundStyle() {
      if (this.image) {
        return `linear-gradient(-99deg, #15616d 0, rgba(20, 33, 61, 0) 61%), url(${this.image}) 50% /cover no-repeat, var(--primary)`;
      } else {
        return `linear-gradient(-99deg, #15616d 0, rgba(20, 33, 61, 0) 61%);`;
      }
    },
  },
};
</script>
