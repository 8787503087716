import { ref } from 'vue'

const SearchTable = () => {

  const searchresult = ref([])
  const search_error = ref(null)

  const start_search = async (table=null,searchKeyWords = null) => {
    try {
      let apiUrl = `https://dashboard.imslearn.org/api/searchByTable?table=${table}&searchKeyWords=${searchKeyWords}`;     
    
      let data = await fetch(apiUrl)
      if (!data.ok) {
        throw Error('no available courses')
      }
       await data.json().then((res) => {
        if (res.success && Array.isArray(res.data) && (res.data.length>0)) {
          searchresult.value = res.data
        }else{
          console.log('unable to load data')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
          throw Error("No courses found.  Try a different search term?")
        }
      })
    }
    catch (err) {
      search_error.value = err.message
    }
  }

  return { searchresult, search_error, start_search }
}

export default SearchTable