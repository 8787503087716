import { ref } from 'vue'
import store from '@/store';
const GetHomeSections = () => {

  const loadedHomeSections = ref([])
  const home_error = ref(null)

  const loadHomeSections = async () => {
    try {
      let data = await fetch('https://dashboard.imslearn.org/api/home_sections')
      if (!data.ok) {
        throw Error('no available home sections')
      }
       await data.json().then((res) => {
        if (res.success) {
          loadedHomeSections.value = res.data
          store.commit('setHomeSections',  res.data);
        }else{
          console.log('unable to load data in home sections')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      home_error.value = err.message
    }
  }

  return { loadedHomeSections, home_error, loadHomeSections }
}

export default GetHomeSections