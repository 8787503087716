import { ref } from 'vue'
import store from '@/store';
const GetVideos = () => {

  const loadedVideos = ref([])
  const videos_error = ref(null)

  const loadVideos = async (limit=null) => {
    try {
      let apiUrl = 'https://dashboard.imslearn.org/api/videos';
      if (limit !== null && limit !== undefined) {
        apiUrl += `?limit=${limit}`;
      }
      let data = await fetch(apiUrl)
      if (!data.ok) {
        throw Error('no available courses')
      }
      await data.json().then((res) => {
        if (res.success) {
          loadedVideos.value = res.data
          store.commit('setVideos',  res.data);
        }else{
          console.log('unable to load data for videos')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      videos_error.value = err.message
    }
  }

  return { loadedVideos, videos_error, loadVideos }
}

export default GetVideos