import { ref } from 'vue'
import store from '@/store';
const GetBanner = () => {

  const loadedBanner = ref([])
  const banner_error = ref(null)

  const loadBanner = async () => {
    try {
      let data = await fetch('https://dashboard.imslearn.org/api/banner')
      if (!data.ok) {
        throw Error('no available banner sections')
      }
       await data.json().then((res) => {
        if (res.success) {
          loadedBanner.value = res.data
          store.commit('setBanner',  res.data);
        }else{
          console.log(res)
          throw Error('server error')
        }
      })
    }
    catch (err) {
      banner_error.value = err.message
    }
  }

  return { loadedBanner, banner_error, loadBanner }
}

export default GetBanner