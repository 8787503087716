<template>
  <NavComponent />
  <section id="singleCourse">
    <div class="colored_banner">
      <h1 v-if="courseObj">{{ courseObj.displayname }}</h1>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
        <div class="col-md-6 col-lg-6 col-xs-12 p-0" v-if="courseObj">
          <img v-if="courseObj" class="courseImage" :src="courseObj.image" />
            <!-- Trainers on small devices -->
          <div class="col-md-3 col-lg-3 col-xs-12 d-md-none d-sm-block">
            <div
              v-if="
                courseObj && courseObj.trainers && courseObj.trainers.length > 0
              "
              class="trainers_cnt"
            >
              <h3 class="trainsers_section_label">Trainers</h3>
              <Trainer
                v-for="(obj, index) in courseObj.trainers"
                :key="index"
                :trainer="obj"
                :last="index === courseObj.trainers.length - 1"
              />
            </div>
          </div>
          <div
            class="singleCourseBody p-4 p-sm-0"
            v-html="courseObj.summary"
          ></div>
          <div class="mt-4 mb-4 text-center">
            <a
              @click="EnrolInCourseInMoodle(courseObj.id)"
              class="btn btn-primary ms-md-2"
              role="button"
            >
              Enrol
            </a>
          </div>
        </div>
        <!-- trainers on large devices -->
        <div class="col-md-3 col-lg-3 col-xs-12 d-md-block d-none">
          <div
            v-if="
              courseObj && courseObj.trainers && courseObj.trainers.length > 0
            "
            class="trainers_cnt"
          >
            <h3 class="trainsers_section_label">Trainers</h3>
            <Trainer
              v-for="(obj, index) in courseObj.trainers"
              :key="index"
              :trainer="obj"
              :last="index === courseObj.trainers.length - 1"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>

<script>
import { watchEffect } from "vue";
import GetOneCourse from "@/composables/GetOneCourse";
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";
import Trainer from "@/views/components/Trainer";
export default {
  name: "ModuleView.vue",
  components: {
    NavComponent,
    Trainer,
    FooterComponent,
  },
  data() {
    return {
      courseObj: null,
      courseId: null,
      course_error: null,
    };
  },
  computed: {},
  mounted() {
    this.courseId = JSON.parse(this.$route.query.id);
    const { loadedCourse, course_error, loadCourse } = GetOneCourse();
    watchEffect(() => {
      if (loadedCourse.value) {
        this.courseObj = loadedCourse.value;
        this.course_error = null;
      } else {
        console.log(loadedCourse.value);
      }
      if (!this.isRefEmpty(course_error)) {
        this.course_error = course_error.value;
      }
    });
    loadCourse("modules", this.courseId);
  },
};
</script>


<style >
.trainers_cnt {
  border: none;
  margin-top: 40vh;
}
.trainsers_section_label {
  padding-left: 15px;
  font-size: 25px;
  margin-bottom: 30px;
}
</style>

