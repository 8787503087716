<template>
  <NavComponent :showSearch="false" />
  <section id="courses">
    <div class="colored_banner">
      <!-- hidden only on xs -->
      <h1>Search Results</h1>
    </div>
    <div class="container">
      <!-- inside describtion from home sections -->
      <div>
        <div class="offset-md-3 offset-lg-3 col-md-6 col-lg-6 col-xs-12 p-0">
          <p class="text-center">Search keyword: {{ searchInput }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-xs-12" id="courses_container">
          <div id="filter_bar" class="container-fluid">
            <div class="row">
              <!-- Right section with search input -->
              <div
                class="offset-lg-4 col-lg-4 offset-md-3 col-md-6 offset-sm-2 col-sm-8 col-xs-12"
              >
                <div class="input-group mb-3 search_courses">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search our modules"
                    aria-label="Search our modules"
                    aria-describedby="basic-addon2"
                    v-model="searchInput"
                    @keyup.enter="search()"
                  />
                  <span
                    class="input-group-text search_btn"
                    @click="search"
                    id="basic-addon2"
                    :class="{ button_active: searchInput }"
                  >
                    Search</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row">
              <!-- modules section -->
              <div class="col-md-12 col-lg-12 col-xs-12 search_section">
                <h2>Modules</h2>
                <div v-if="!(modules && modules.length)">
                  <p>No modules to show</p>
                </div>
                <div
                  v-else
                  class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
                >
                  <CourseCell
                    v-for="(obj, index) in modules"
                    :key="index"
                    :course="obj"
                  />
                </div>
              </div>

              <!-- courses section -->
              <div class="col-md-12 col-lg-12 col-xs-12 search_section">
                <h2>Courses</h2>
                <div v-if="!(courses && courses.length)">
                  <p>No courses to show</p>
                </div>
                <div
                  v-else
                  class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
                >
                  <CourseCell
                    v-for="(obj, index) in courses"
                    :key="index"
                    :course="obj"
                    :type="'course'"
                  />
                </div>
              </div>

              <!-- videos section -->
              <div class="col-md-12 col-lg-12 col-xs-12 search_section">
                <h2>Videos</h2>
                <div v-if="!(videos && videos.length)">
                  <p>No videos to show</p>
                </div>
                <div
                  v-else
                  class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
                >
                  <VideoCell
                    v-for="(obj, index) in videos"
                    :key="index"
                    :video="obj"
                  />
                </div>
              </div>
            </div>
          </div>

          <Loader :isLoading="isLoading" />
        </div>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>
<script>
import { watch, watchEffect, ref } from "vue";
//component
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";
import SearchAll from "@/composables/SearchAll";
import CourseCell from "@/views/components/CourseCell";
import VideoCell from "@/views/components/VideoCell";
export default {
  name: "SearchView",
  components: {
    NavComponent,
    FooterComponent,
    CourseCell,
    VideoCell,
  },
  data() {
    return {
      loadedData: ref([]),
      courses_error: null,
      isLoading: false,
      searchInput: "",
      active_view: "courses", // default to courses
    };
  },
  mounted() {
    const searchParam = this.$route.query.search;

    if (searchParam) {
      this.searchInput = searchParam;
      this.search();
    }
    this.isLoading = true;
  },
  methods: {
    search() {
      this.fetchSearchResults(this.searchInput);
    },
    // no_result_in_current_view() {
    //   if (this.active_view == "courses") {
    //     return !(this.courses && this.courses.length);
    //   } else if (this.active_view == "modules") {
    //     return !(this.modules && this.modules.length);
    //   } else if (this.active_view == "videos") {
    //     return !(this.videos && this.videos.length);
    //   }
    // },

    fetchSearchResults(keyword) {
      const { searchresult, search_error, start_search } = SearchAll();
      // todo test with updated API that return category parent
      watch([searchresult, search_error], () => {
        if (searchresult.value) {
          this.loadedData.value = searchresult.value;
          this.courses_error = null;
          this.isLoading = false;
        }
        if (!this.isRefEmpty(search_error)) {
          this.courses_error = search_error.value;
          this.isLoading = false;
        }
      });
      start_search(keyword);
    },
  },
  computed: {
    courses() {
      if (this.loadedData.value) {
        return this.loadedData.value.find((item) => item.type === "courses")
          .data;
      } else {
        return [];
      }
    },
    modules() {
      if (this.loadedData.value) {
        let res = this.loadedData.value.find(
          (item) => item.type === "modules"
        ).data;
        return res;
      } else {
        return [];
      }
    },
    videos() {
      if (this.loadedData.value) {
        let res = this.loadedData.value.find(
          (item) => item.type === "videos"
        ).data;
        return res;
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped >
.search_courses {
  width: 100%;
}
.search_section {
  margin-bottom: 60px;
}
.search_section h2 {
  margin-bottom: 10px;
}
</style>