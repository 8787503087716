// store.js
import { createStore } from 'vuex';
const store = createStore({
  state: {
    loadedHomeSections:null, 
    loadedCategories:null,
    loadedBanner: null,
    loadedLangs:null,
    loadedVideos: null,
    loadedAboutUs:null,
    loadedMobileApp:null,
    privacyPolicy:null
  },
  mutations: {
    setHomeSections(state, data) {
      state.loadedHomeSections = data
    },
    setCategories: (state,data)=> { state.loadedCategories = data},
    setBanner: (state,data)=>{ state.loadedBanner = data},
    setLangs: (state,data) => {state.loadedLangs = data},
    setVideos: (state,data) => {state.loadedVideos = data},
    setAboutUs:(state,data) => {state.loadedAboutUs = data},
    setMobileApp:(state,data) => {state.loadedMobileApp = data},
    setPrivacyPolicy:(state,data) => {state.privacyPolicy = data},
  },
  actions: {
    // async 
  },
  getters: {
    getHomeSections: (state) => state.loadedHomeSections ?? [],
    getCategories: (state) => state.loadedCategories ?? [],
    getBanner: (state)=> state.loadedBanner ?? [],
    getLang: (state)=> state.loadedLangs??[],
    getVideos: (state)=> state.loadedVideos??[],
    getAboutUs: (state)=> state.loadedAboutUs??[],
    getMobileApp: (state)=> state.loadedMobileApp??[],
    getPrivacyPolicy:(state)=>state.privacyPolicy??[]
  }
});

export default store;
