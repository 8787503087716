<template>
  <footer>
    <div class="container p-5 py-lg-5">
      <div class="row justify-content-center">
        <div
          class="col-sm-6 col-md-6 text-center text-lg-start d-flex flex-column item"
        >
          <img id="footer_logo" src="@assets/img/Logo.png" />
          <p id="footer_desc">
            Good journalism. Better societies. <br/>
      IMS (International Media Support) pushes for quality journalism, 
      challenges repressive laws and keeps media workers of all genders safe, 
      so that they can do their jobs. Through alliances and innovation, 
        we help free, independent media contribute to positive change and better societies.
          </p>
        </div>
        <div
          class="col-sm-4 col-md-2 col-xl-2 col-12 text-center text-lg-start d-flex flex-column item"
        >
          <ul class="list-unstyled">
            <li>
            <router-link to="/" class="link-secondary"> Home </router-link>
            </li>
            <li>
            <router-link  to="/courses" class="link-secondary"> Courses </router-link>
           </li>
            <li>
            <router-link to="/modules" class="link-secondary"> Modules </router-link>
            </li>
            <li>
            <router-link to="/videos" class="link-secondary"> Videos </router-link>
            </li>
          </ul>
        </div>
        <div
          class="col-sm-4 col-md-2 col-12 text-center text-lg-start d-flex flex-column item"
        >
          <ul class="list-unstyled">
           
            <li>
            <router-link to="/privacy_policy" class="link-secondary"> Privacy Policy  </router-link>
            </li>
            <!-- <li>
            <a target="_blank" href="https://www.mediasupport.org/privacy-policy/" class="link-secondary"> Privacy Policy </a>
            </li> -->
            <li>
            <router-link to="/about" class="link-secondary"> About </router-link>
            </li>
            <li>
            <router-link to="/mobile_app" class="link-secondary"> Mobile App </router-link>
            </li>
            
            <!-- <li>
            <router-link to="/faq" class="link-secondary">FAQ’s </router-link>
            </li> -->
          </ul>
        </div>
        <div
          class="col-lg-2 col-12 text-center text-lg-start d-flex flex-column align-items-lg-start order-lg-last item social"
        >
          <div class="fw-bold d-flex align-items-center mb-2 footer_email">
            <span
              class="d-flex justify-content-center align-items-center bs-icon-sm bs-icon-rounded bs-icon me-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-envelope-at"
                viewBox="0 0 16 16"
              >
                <path
                  d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"
                />
                <path
                  d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"
                />
              </svg> </span
            ><span>IMS LEARN</span>
          </div>
          <p class="text-muted copyright">info@imslearn.org</p>
        </div>
      </div>
      <hr />
      <div class="d-flex justify-content-between align-items-center pt-3">
        <p class="text-muted mb-0">Copyright © 2024&nbsp;</p>
        <ul v-if="show_social_links" class="list-inline mb-0">
          <li class="list-inline-item list-inline-item list-inline-item">
            <svg
              class="bi bi-facebook"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              viewbox="0 0 16 16"
            >
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
              ></path>
            </svg>
          </li>
          <li class="list-inline-item list-inline-item list-inline-item">
            <svg
              class="bi bi-twitter"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              viewbox="0 0 16 16"
            >
              <path
                d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
              ></path>
            </svg>
          </li>
          <li class="list-inline-item list-inline-item list-inline-item">
            <svg
              class="bi bi-instagram"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              viewbox="0 0 16 16"
            >
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
              ></path>
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterComponent",
  components: {},
  data() {
    return {
      show_social_links: false,
    };
  },
};
</script>