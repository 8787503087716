<template>
  <div id="courses_container" class="col-12 col-md-7 col-lg-7">
    <div id="Courses_header">
      <div v-if="titleObject">
        <h1 class="d-inline-block float-none">{{ titleObject.title }}</h1>
        <button class="btn btn-primary float-end" type="button"
        @click="navigateTo('modules_page')">
          Check All Modules
        </button>
        <p>{{ titleObject.description }}<br /><br /></p>
      </div>
      <div v-else>
        <h1 class="d-inline-block float-none">Feature Modules</h1>
        <button class="btn btn-primary float-end" type="button" @click="navigateTo('modules_page')">
          Check All Modules
        </button>
        <p>The most populer modules<br /><br /></p>
      </div>

      <div
        v-if="loadedCourses && loadedCourses.length > 0"
        class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3"
      >
        <CourseCell
          v-for="(course, index) in loadedCourses"
          :course="course"
          :key="index"
        />
      </div>
    </div>
    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import GetCoursesModules from "@/composables/GetCoursesModules";
import CourseCell from "@/views/components/CourseCell";
import { watchEffect } from "vue";
export default {
  name: "ModulesComponent",
  components: { CourseCell },
  props: {
    titles: [],
  },
  data() {
    return {
      // loadedCategories: [],
      loadedCourses:[],
      courses_error: null,
      isLoading: false, //todo check how to use isLoading with new way of fetching data from store
    };
  },
  mounted() {
    const { loadedCourses, courses_error, load_data } = GetCoursesModules();
    watchEffect(() => {
      if (!this.isRefEmpty(loadedCourses)) {
          this.loadedCourses = loadedCourses.value;
          this.courses_error = null;
          this.isLoading = false;
        }
      if (!this.isRefEmpty(courses_error)) {
        this.courses_error = courses_error.value;
        this.isLoading = false;
      }
    });
    load_data('modules',null,null,null,1);
  },
  computed: {
    // Get the 'modules' object from the 'titles' array
    titleObject() {
      if (this.titles) {
        return this.titles.find((title) => title.name === "modules_section");
      }
      return null;
    },
  },

};
</script>