<template>
    <router-view/>
</template>

<style>
#app {
  font-family: League Spartan,sans-serif;
}

h1, h2, h3, h4, h5, h6{
  font-family: League Spartan,sans-serif;
}
</style>
