<template>
  <NavComponent />

  <BannerComponent />
  <div v-if="isLoading" class="preloader">
    <div class="spinner"></div>
  </div>
  <div v-else>
    <LanguagesComponent :titles="$store.getters.getHomeSections" />

    <section id="courses">
      <div class="container-fluid">
        <div class="row">
          <!-- we are now using this component to display the featured coureses -->
          <ModulesComponent :titles="$store.getters.getHomeSections" />
          <VideoComponent :titles="$store.getters.getHomeSections" />
        </div>
      </div>
    </section>

    <TestimonialsComponent />

    <FooterComponent />
  </div>
</template>

<script>
import GetHomeSections from "@/composables/GetHomeSections";
// component
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";
import BannerComponent from "@/views/components/home/Banner";
import LanguagesComponent from "@/views/components/home/Langages";
import ModulesComponent from "@/views/components/home/Modules"
import VideoComponent from "@/views/components/home/Videos";
import TestimonialsComponent from "@/views/components/home/Testimonials";

import { watchEffect } from "vue";
export default {
  name: "HomeView",
  components: {
    NavComponent,
    FooterComponent,
    BannerComponent,
    LanguagesComponent,
    ModulesComponent,
    VideoComponent,
    TestimonialsComponent,
  },
  data() {
    return {
      // loadedHomeSections: [],
      home_error: null,
      isLoading: true,
    };
  },

  mounted() {
    const { loadedHomeSections, home_error, loadHomeSections } = GetHomeSections();
    
    watchEffect(() => {
      if (home_error && home_error.value != null) {
        this.home_error = home_error.value;
      }
      // todo change loader to be after store return data
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });
    loadHomeSections();
  },

  methods: {},
};
</script>

<style scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
