<template>
  <nav
    class="bc_navbar navbar navbar-expand-md bg-body flex-grow-1 justify-content-between py-3"
    style="position: sticky"
  >
    <div class="container">
      <a class="navbar-brand d-flex align-items-center" href="#"
        ><span>
          <img
            id="logo"
            src="@assets/img/Logo.png"
            width="237"
            height="49" /></span
      ></a>
      <button
        data-bs-toggle="collapse"
        class="navbar-toggler"
        data-bs-target="#navcol-2"
      >
        <span class="visually-hidden">Toggle navigation</span>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navcol-2">
        <ul
          class="navbar-nav d-xl-flex flex-grow-1 justify-content-center align-items-xl-center"
        >
          <li class="nav-item">
            <div class="input-group flex-nowrap" v-if="showSearch">
              <input
                type="search"
                tabindex="1"
                id="search"
                class="form-control"
                placeholder="Search"
                @keyup.enter="performSearch()"
              />
            </div>
          </li>
          <li class="nav-item">
            <router-link
              to="/"
              class="nav-link"
              :class="{
                active: $route.path === '/',
              }"
            >
              Home
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/courses"
              class="nav-link"
              :class="{
                active: $route.path.startsWith('/courses'),
              }"
            >
              Courses
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              to="/modules"
              class="nav-link"
              :class="{
                active: $route.path.startsWith('/modules'),
              }"
            >
              Modules
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/videos"
              class="nav-link"
              :class="{
                active: $route.path.startsWith('/videos'),
              }"
            >
              Videos
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/about"
              class="nav-link"
              :class="{
                active: $route.path == '/about',
              }"
            >
              About
            </router-link>
          </li>
        </ul>
        <a
          class="btn btn-primary ms-md-2"
          role="button"
          id="my_courses"
          href="https://my.imslearn.org/my/courses.php"
        >
        My Learning
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "NavComponent",
  components: {},
  data() {
    return {};
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    performSearch() {
      const searchTerm = document.getElementById("search").value;
      // alert("Searching for: " + searchTerm);
      this.navigateToWithParam("search_page", "search", searchTerm);
    },
  },
};
</script>