import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import LoaderPlugin from './mixins/loaderPlugin' // no brackets means imports the default export
import { commonMixin } from './mixins/commonMixin'; // brackets means imports a named export named 'commonMixin' 
import './assets/css/Hero-Carousel-images.css';
import './assets/css/Navbar-Right-Links-icons.css';
import './assets/css/styles.css';
import './assets/css/responsive.css'
const app = createApp(App);
app.use(router);
app.use(store)
app.use(LoaderPlugin);
app.mixin(commonMixin);
app.mount('#app');
