<template>
  <NavComponent />
  <section id="About">
    <div class="colored_banner">
      <h1>Instructor's Manual</h1>
    </div>
    <div class="container">
      <div class="row">
        <!-- <div class="col-md-3 col-lg-3 col-xs-12"></div>
        <div class="col-md-6 col-lg-6 col-xs-12 p-0" >
          <div
          :style="{ backgroundImage: `url(${getImagePath()})` }"
            id="about_img"
          ></div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12"></div> -->
        <div id="iframe-container">
         
          <iframe
            src="https://imslearn.org/manuals"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>

<script>
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";
export default {
  name: "Manuals",
  components: {
    NavComponent,
    FooterComponent,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getImagePath() {
      // Assuming your image file is named "example.jpg"
      return require("@/assets/img/under_construction.jpg");
    },
  },
  mounted() {},
};
</script>

<style scoped>
#iframe-container {
  width: 100%;
  overflow: hidden; /* To hide any content outside the container */
}
iframe {
  width: 100%;
  height: 100vh;
  border: none; /* Optional: removes iframe border */
}
</style>


