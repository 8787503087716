import { ref } from 'vue'

const GetCoursesModules = () => {

  const loadedCourses = ref([])
  const courses_error = ref(null)

  const load_data = async (table_name=null,categId = null,lang=null,tagId=null,feature=null) => {
    try {
      let apiUrl = 'https://dashboard.imslearn.org/api/data';
      const queryParams = [];
      let perPage = 60;

      queryParams.push(`perPage=${perPage}`);
      // queryParams.push('table=courses');
      if (table_name !== null) {
        queryParams.push(`table=${table_name}`);
      }
      if (categId !== null) {
        queryParams.push(`categoryId=${categId}`);
      }
      if (lang !== null) {
        queryParams.push(`lang=${lang}`);
      }
      if (tagId !== null) {
        queryParams.push(`tagid=${tagId}`);
      }
      if (feature !== null) {
        queryParams.push(`feature=${feature}`);
      }
      if (queryParams.length > 0) {
        apiUrl += `?${queryParams.join('&')}`;
      }
    
      let data = await fetch(apiUrl)
      if (!data.ok) {
        throw Error('no available courses')
      }
       await data.json().then((res) => {
        if (res.success && (res.data !=null) && Array.isArray(res.data.data) && (res.data.data.length>0) ) {
          loadedCourses.value = res.data.data
        }else{
          console.log('unable to load data')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
          throw Error("No courses found. Try a different search term?")
        }
      })
    }
    catch (err) {
      courses_error.value = err.message
    }
  }

  return { loadedCourses, courses_error, load_data }
}

export default GetCoursesModules