import { ref } from 'vue'

const GetAllByLang = () => {

  const loaded_data = ref([])
  const data_error = ref(null)

  const get_data = async (lang = null) => {
    try {
      let apiUrl = 'https://dashboard.imslearn.org/api/getDataByLang';
      let queryParams = [];
      if (lang !== null) {
        queryParams.push(`lang=${lang}`);
      }
      if (queryParams.length > 0) {
        apiUrl += `?${queryParams.join('&')}`;
      }
      let data = await fetch(apiUrl)
      if (!data.ok) {
        throw Error('no available data by languages')
      }
       await data.json().then((res) => {
        if (res.success && res.data != null) {
          loaded_data.value = res.data
        }else{
          console.log('unable to load data by language')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
          throw Error("No courses found.  Try a different search term?")
        }
      })
    }
    catch (err) {
      data_error.value = err.message
    }
  }

  return { loaded_data, data_error, get_data }
}

export default GetAllByLang