import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '@/views/AboutView'
import ModulesView from '@/views/ModulesView'
import CourseView from '@/views/CourseView'
import ModuleView from '@/views/ModuleView'
import CoursesView from '@/views/CoursesView'
import VideosView from '@/views/VideosView'
import VideoView from '@/views/VideoView'
import SearchView from '@/views/SearchView'
import LangResultView from '@/views/LangResultView'
import TestimonialView from '@/views/TestimonialView'
import MobileApp from '@/views/MobileApp.vue'
// import FAQ from '@/views/FAQ'
import PrivacyPolicy from '@/views/PrivacyPolicy'
// import TermsService from '@/views/TermsService'
import Manuals from '@/views/Manuals'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/mobile_app',
    name: 'mobile_app_page',
    component: MobileApp
  },
  {
    path: '/modules',
    name: 'modules_page',
    component: ModulesView
  },
  {
    path: '/courses',
    name: 'courses_page',
    component: CoursesView
  },

  {
    path: '/search',
    name: 'search_page',
    component: SearchView
  },
  {
    path: '/lang',
    name: 'lang_page',
    component: LangResultView
  },
  {
    path: '/videos',
    name: 'videos',
    component: VideosView
  },
  {
    path: '/video',
    name: 'single_video',
    component: VideoView
  },
  {
    path: '/course',
    name: 'single_course',
    component: CourseView
  },
  {
    path: '/module',
    name: 'single_Module',
    component: ModuleView
  },
  {
    path: '/testimonial',
    name: 'single_testimonial',
    component: TestimonialView
  },
  {
    path: '/manuals',
    name: 'manuals',
    component: Manuals
  },
  // deprecated pages
  // {
  //   path: '/faq',
  //   name: 'faq',
  //   component: FAQ
  // },
  {
    path: '/privacy_policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy
  },
  // {
  //   path: '/terms_of_service',
  //   name: 'termsOfService',
  //   component: TermsService
  // }




]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
})

export default router
