<template>
  <div class="card trainers" >
    <div class="row">
      <div
        class="col-lg-3 col-md-3 col-xs-6 image_circle"
        :style="{ 'background-image': 'url(' + trainer.image + ')' }"
      ></div>
      <div class="col-lg-8 col-md-8 col-xs-6">
        <div class="card-body trainer_name">
          <h5 class="card-title">{{ trainer.name }}</h5>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-xs-12">
        <div class="card-body">
          <p>
            {{cleanBiography }}
          </p>
        </div>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "Trainer",
  props: ['trainer', 'last'],
  components: {},
  mounted() {},
  methods: {},
  computed: {
    cleanBiography() {
      // remove HTML tags
      if (this.trainer && this.trainer.biography) {
        return this.trainer.biography.replace(/<[^>]+>/g, "");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.trainers {
  border: 0px;
}
.trainers .image_circle {
  background-position: 50%;
    background-size: cover;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-left: 8%;
}
.trainers .trainer_name {
  text-align: left;
  padding-left: 0px;
  
}
.trainers .trainer_name h5 {
  margin: 0px;
  color: #1E1E1E;
}

.card.trainers {
  position: relative;
}
.line {
  width: 50%;
    height: 1px;
    background-color: #000000;
    margin: 5px 0px 30px 15px;
}

.card.trainers:last-child .line {
  display: none; /* Hide the line for the last trainer */
}
</style>