<template>
  <NavComponent />
  <section id="courses">
    <div class="colored_banner">
      <h1>Courses</h1>
    </div>
    <div class="container">
      <div v-if="titleObject" class="row">
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
        <div class="col-md-6 col-lg-6 col-xs-12 p-0">
          <div
            id="about_info"
            class="p-4 p-sm-0"
            v-html="titleObject.inside_description"
          ></div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12" id="courses_container">
          <div id="filter_bar" class="container-fluid">
            <div class="row">
              <!-- Left section with dropdowns -->
              <div class="col-sm-6 col-xs-12">
                <div class="btn-group">
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle filter_btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ selectedLang.name }}
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        href="#"
                        class="dropdown-item"
                        @click.prevent="activeLang('all')"
                        >All</a
                      >
                    </li>
                    <li
                      v-for="(language, index) in loadedLanguages"
                      :key="index"
                    >
                      <a
                        class="dropdown-item"
                        @click.prevent="activeLang(language)"
                        href="#"
                        >{{ language.name }}
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="btn-group">
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle filter_btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ selectedTag.name }}
                  </button>
                  <ul class="dropdown-menu">
                    <li v-for="(tag, index) in tags" :key="index">
                      <a
                        href="#"
                        class="dropdown-item"
                        @click.prevent="activeTag(tag)"
                        >{{ tag.name }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Right section with search input -->
              <div class="col-sm-6 col-xs-12">
                <div class="input-group mb-3 search_courses">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search our courses"
                    aria-label="Search our modules"
                    aria-describedby="basic-addon2"
                    v-model="searchInput"
                    @keyup.enter="search()"
                  />
                  <span
                    class="input-group-text search_btn"
                    @click="search"
                    id="basic-addon2"
                    :class="{ button_active: searchInput }"
                  >
                    Search</span
                  >
                </div>
              </div>
            </div>
          </div>
        
          <!-- courses loader -->
          <div>
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xs-12">
                <!-- Error message div -->
                <div v-if="courses_error" class="alert alert-danger mt-3">
                  {{ courses_error }}
                </div>
                <div v-else
                  class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
                >
                  <CourseCell
                    v-for="(course, index) in loadedCourses.value"
                    :key="index"
                    :course="course"
                    :type="'course'"
                  />
                </div>
              </div>
            </div>
          </div>

          <Loader :isLoading="isLoading" />
        </div>
        <!-- <div class="col-md-1 col-lg-2 col-xs-12"></div> -->
      </div>
    </div>
    <!-- // pagination to be added later -->
    <div class="w3-container">
      <div class="w3-bar" v-if="1 == 0">
        <a href="#" class="w3-button"
          ><i class="fa fa-angle-left" style="font-size: 24px"></i
        ></a>
        <a href="#" class="w3-button w3-green">1</a>
        <a href="#" class="w3-button">2</a>
        <a href="#" class="w3-button">3</a>
        <a href="#" class="w3-button">4</a>
        <a href="#" class="w3-button"
          ><i class="fa fa-angle-right" style="font-size: 24px"></i>
        </a>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>
<script>
import { watch,watchEffect,ref } from "vue";
// composables
import GetCoursesModules from "@/composables/GetCoursesModules";
import GetHomeSections from "@/composables/GetHomeSections";
import GetLanguages from "@/composables/GetLanguages";
import GetTags from "@/composables/GetTags";
import SearchTable from "@/composables/SearchTable";
//component
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";
import CourseCell from "@/views/components/CourseCell";
export default {
  name: "CoursesView",
  components: {
    NavComponent,
    FooterComponent,
    CourseCell,
  },
  data() {
    return {
      loadedCourses: ref([]), 
      loadedLanguages: [],
      tags: [],
      tags_error: null,
      courses_error: null,
      lang_error: null,
      isLoading: false,
      selectedLang: { name: "All", lang: null },
      selectedTag: { name: "All", id: null },
      searchInput: "",
    };
  },
  mounted() {
    const { loadedHomeSections, home_error, loadHomeSections } =  GetHomeSections();
    loadHomeSections();
    const { loadedLanguages, lang_error, loadLanguages } = GetLanguages();
    const { loadedTags, tags_error, loadTags } = GetTags();
    this.fetchCourses();
    watchEffect(() => {
      // Load languages
      if (!this.isRefEmpty(loadedLanguages)) {
        this.loadedLanguages = loadedLanguages.value;
        this.updateLangName();
        this.lang_error = null;
      }
      if (!this.isRefEmpty(lang_error)) {
        this.lang_error = lang_error.value;
      }

      // load tags
      if (!this.isRefEmpty(loadedTags)) {
        this.tags = loadedTags.value;
      }
    });
    loadLanguages();
    loadTags();
  },
  computed: {
    titleObject() {
      return this.$store.getters.getHomeSections.find(
        (title) => title.name === "courses_section"
      );
    },
  },
  methods: {
    fetchCourses() {
      const { loadedCourses, courses_error, load_data } = GetCoursesModules();
      watch([loadedCourses, courses_error], () => {
        if (!this.isRefEmpty(loadedCourses)) {
          this.loadedCourses.value = loadedCourses.value;
          this.courses_error = null;
          this.isLoading = false;
        }
        if (!this.isRefEmpty(courses_error)) {
          this.courses_error = courses_error.value;
          this.isLoading = false;
        }
      });

      load_data('courses',null,
        this.selectedLang.lang,
        this.selectedTag.id);
      this.isLoading = true; //uncomment to make the loader work each time user filter
    },

    activeTag(tag) {
      this.selectedTag.name = tag.name;
      this.selectedTag.id = tag.id;
      this.fetchCourses();
    },
    activeLang(lang) {
      if (lang == "all") {
        this.selectedLang.name = "All";
        this.selectedLang.lang = null;
      } else {
        this.selectedLang.name = lang.name;
        this.selectedLang.lang = lang.shortname;
      }

      this.fetchCourses();
    },
  
    search() {
      // Your custom logic when search button is clicked
      this.fetchSearchResults(this.searchInput);
    },

    fetchSearchResults(keyword) {
      const { searchresult, search_error, start_search } = SearchTable();
      // todo test with updated API that return  parent
      watchEffect(() => {
        if (searchresult.value) {
          this.loadedCourses.value = searchresult.value;
          this.courses_error = null;
          this.isLoading = false;
        }
        if (!this.isRefEmpty(search_error)) {
          this.courses_error = search_error.value;
          this.isLoading = false;
        }
      });
      start_search("courses", keyword);
    },

    //Called when languages are fetched, to check if the cat that where sent via paramter match one of them
    updateLangName() {
      let foundLanguage = this.loadedLanguages.find(
        (lang) => lang.shortname == this.selectedLang.lang
      );
      if (foundLanguage) {
        this.selectedLang.name = foundLanguage.name; // update title from All to the cat name that match the id
      }
    },
  },
};
</script>
