<template>
  <div class="col">
   
    <div class="card-container" v-if="type == 'course'" >
      <div class="card">
        <img
          class="card-img-top w-100 d-block fit-cover"
          :src="course.image"
          :alt="course.displayname"
        />
        <div class="card-body p-4">
          <p v-if="course.category" class="text-primary card-text mb-0 categoryName">
            {{ getLanguageName(course.lang) }}
         
          </p>
          <p v-else class="text-primary card-text mb-0">Public</p>
          <span class="course_tag"> {{ getTagNameIfAllowed(course) }}</span>
          <h4 class="card-title">{{ course.displayname }}</h4>
          <div class="d-flex">
            <button
              class="btn btn-primary float-end"
              type="button"
              @click="navigateToWithParam('single_course','id',course.id)"
            >

              <strong >Open Course</strong>
            </button>
          </div>
        </div>
      </div>
      <div class="overlay" @click="navigateToWithParam('single_course','id',course.id)">
        <p>{{ course.displayname }}</p>
        <p>
          <span class="inline_duration">
            {{ formatDuration(course.duration)  }}
          </span>
        </p>
        <div class="inline_summary" v-html="course.cms_description"></div>
      </div>
    </div>

    <div class="card-container" v-else>
      <div class="card">
        <img
          class="card-img-top w-100 d-block fit-cover"
          :src="course.image"
          :alt="course.displayname"
        />
        <div class="card-body p-4">
          <p v-if="course.category" class="text-primary card-text mb-0 categoryName">
            {{ getLanguageName(course.lang) }}
         
          </p>
          <p v-else class="text-primary card-text mb-0">Public</p>
          <span class="course_tag"> {{ getTagNameIfAllowed(course) }}</span>
          <h4 class="card-title">{{ course.displayname }}</h4>
          <div class="d-flex">
            <button
              class="btn btn-primary float-end"
              type="button"
              @click="navigateToWithParam('single_Module','id',course.id)"
            >
              <strong >Open Module</strong>
            </button>
          </div>
        </div>
      </div>
      <div class="overlay" @click="navigateToWithParam('single_Module','id',course.id)">
        <p>{{ course.displayname }}</p>
        <p>
          <span class="inline_duration">
            {{ formatDuration(course.duration)  }}
          </span>
        </p>
        <div class="inline_summary" v-html="course.cms_description"></div>
      </div>
    </div>



  </div>
</template>
<script>
export default {
  name: "CourseCell",
  props: {
    course: {},
    type: "", // when type is category, then the course object hold the data of the category
  },
  components: {},
  mounted() {},
  methods: {
    getLanguageName(shortname) {
      const language = this.$store.getters.getLang.find(lang => lang.shortname === shortname);
      return language ? language.name : "Language Not Found";
    }
  },
};
</script>

<style  scoped>
.inline_duration {
  background: #fca311;
    padding: 5px;
    font-size: 1rem;
    border-radius: 5px;

}
.inline_summary {
  width: 100%;
}
#courses .card-container:hover .overlay {
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}
#courses .card-body .categoryName{
  width: 100%;
}
.course_tag{
  background-color: #78290f;
    color: #fff;
    position: absolute;
    top: 14px;
    right: 0px;
    padding: 3px 10px;
    font-size: 0.8rem;
}
</style>