import { ref } from 'vue'
const GetOneCourse = () => {

  const loadedCourse = ref(null)
  const course_error = ref(null)

  const loadCourse = async (table,id) => {
    try {
      let api = `https://dashboard.imslearn.org/api/data?table=${table}&id=${id}`;
      let data = await fetch(api)
      if (!data.ok) {
        throw Error('This course is not available anymore')
      }
      await data.json().then((res) => {
        if (res.success && (res.data !=null) && Array.isArray(res.data.data) && (res.data.data.length>0) ) {
         
          loadedCourse.value = res.data.data[0]
        }else{
          console.log('unable to load data for course',id)
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      course_error.value = err.message
    }
  }

  return { loadedCourse, course_error, loadCourse }
}

export default GetOneCourse