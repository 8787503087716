import { ref } from 'vue'

const GetTestimonials = () => {

  const loadedTestimonials = ref([])
  const testimonials_error = ref(null)

  const loadTestimonials = async () => {
    try {
      let data = await fetch('https://dashboard.imslearn.org/api/data/testimonials')
      if (!data.ok) {
        throw Error('no available courses')
      }
      await data.json().then((res) => {
        if (res.success) {
          loadedTestimonials.value = res.data
        }else{
          console.log('unable to load data')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      testimonials_error.value = err.message
    }
  }

  return { loadedTestimonials, testimonials_error, loadTestimonials }
}

export default GetTestimonials