export const commonMixin = {
  methods: {
    isRefEmpty(ref) {
      if (!ref){
        return true;
      }
      if (!ref.value) {
        return true;
      }
      if (Array.isArray(ref.value) && ref.value.length === 0) { // if value is empty array
        return true;
      }
      return false;
    },
    isEmpty(param) {
      if (!param)
        return true;
      if (Array.isArray(param) && param.length === 0)
        return true;
      return false;
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    },

    navigateToWithParam(routeName, paramName, paramValue) {
      this.$router.push({
        name: routeName,
        query: { [paramName]: paramValue }
      });
    },

    formatDuration(duration) {
      if (duration==null){
        return 'unset'
      }
      const [hours, minutes] = duration.split(":").map(Number);

      // Check if hours and minutes are valid numbers
      if (!isNaN(hours) && !isNaN(minutes)) {
        // Build the formatted string
        const formattedDuration = [];
        if (hours > 0) formattedDuration.push(`${hours}h`);
        if (minutes > 0) formattedDuration.push(`${minutes}m`);

        // Join the formatted parts
        return formattedDuration.join(":");
      } else {
        return "Invalid Duration";
      }
    },
    // not used now, replaced with enrol course in moodle
    openCourseInMoodle(courseId) {
      let url = `https://my.imslearn.org/course/view.php?id=${courseId}`
      window.open(url, '_blank');
    },
    EnrolInCourseInMoodle(courseId) {
      let url = `https://my.imslearn.org/enrol/index.php?id=${courseId}`
      window.location.href= url;
    },
    getEmbeddedUrl(youtubeLink) {
      const videoId = youtubeLink.split("v=")[1];
      // Construct the embedded URL
      return `https://www.youtube.com/embed/${videoId}`;
    },
    getThumbnailUrl(youtubeLink) {
      const videoId = youtubeLink.split("v=")[1];
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    },

    getTagNameIfAllowed(obj) {
      // Check if the object has a 'tags' property and it's an array
      // Allowed tags are Self-Learning: 16 , and new one to add: ex 17
      if (obj && Array.isArray(obj.tags)) {
        for (const tag of obj.tags) {
          if (tag.id === 16 || tag.id === 17) {
            return tag.name;
          }
        }
      }
      // Return null if no tag with id 16 or 17 is found
      return null;
    }
  },
};