<template>
  <NavComponent />
  <section id="courses">
    <div class="colored_banner">
      <h1>Videos</h1>
    </div>
    <div class="container">
      <!-- inside describtion from home sections -->
      <div v-if="titleObject" class="row">
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
        <div class="col-md-6 col-lg-6 col-xs-12 p-0">
          <div
            id="video_info"
            class="p-4 p-sm-0"
            v-html="titleObject.inside_description"
          ></div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
      </div>


      <div class="row">
        <div class="col-12" id="courses_container">
         
         
          <div v-if="videos_error" class="alert alert-danger mt-3">
            {{ videos_error }}
          </div>
          <div  v-if="videos && videos.length > 0">
            <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
              <VideoCell
              v-for="(video, index) in videos"
                :key="index"
                :video="video"
              />
            </div>
          </div>

          <Loader :isLoading="isLoading" />
        </div>
      </div>
    </div>
    <!-- // pagination to be added later -->
    <div class="w3-container">
      <div class="w3-bar" v-if="1 == 0">
        <a href="#" class="w3-button"
          ><i class="fa fa-angle-left" style="font-size: 24px"></i
        ></a>
        <a href="#" class="w3-button w3-green">1</a>
        <a href="#" class="w3-button">2</a>
        <a href="#" class="w3-button">3</a>
        <a href="#" class="w3-button">4</a>
        <a href="#" class="w3-button"
          ><i class="fa fa-angle-right" style="font-size: 24px"></i>
        </a>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>
<script>
import { watchEffect } from "vue";

import GetVideos from "@/composables/GetVideos";
import VideoCell from "@/views/components/VideoCell";
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";
export default {
  name: "VideosView",
  components: {
    NavComponent,
    FooterComponent,
    VideoCell,
  },
  data() {
    return {
      videos_error: null,
      isLoading: false,
    };
  },
  computed: {
    videos(){
     return this.$store.getters.getVideos;
    },
    titleObject() {
      return this.$store.getters.getHomeSections.find((title) => title.name === "videos_section");
    },
  },
  mounted() {
    const { loadedVideos, videos_error, loadVideos } = GetVideos();
    watchEffect(() => {
      if (videos_error && videos_error.value) {
        this.videos_error = videos_error.value;
      }
    });
    loadVideos();
  },
};
</script>
