import { ref } from 'vue'

const GetTags = () => {

  const loadedTags = ref([])
  const tags_error = ref(null)

  const loadTags = async () => {
    try {
      let apiUrl = 'https://dashboard.imslearn.org/api/tags';
      let data = await fetch(apiUrl)
      if (!data.ok) {
        throw Error('no available courses')
      }
       await data.json().then((res) => {
        if (res.success && Array.isArray(res.data) && (res.data.length>0) ) {
          loadedTags.value = res.data
        }else{
          console.log('unable to load data')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
          throw Error("No tags found. contact web administrator")
        }
      })
    }
    catch (err) {
      tags_error.value = err.message
    }
  }

  return { loadedTags, tags_error, loadTags }
}

export default GetTags