<template>
  <NavComponent />
  <section id="About">
    <div class="colored_banner">
      <h1 v-if="testimonialObj">{{ testimonialObj.name }}</h1>
      <h1 v-else>IMS LEARN</h1>
      <p v-if="testimonialObj">
        {{ testimonialObj.info }}
      </p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
        <div class="col-md-6 col-lg-6 col-xs-12 p-0" v-if="testimonialObj">
          <div
            :style="{ backgroundImage: `url('${testimonialObj.image}')` ,    backgroundSize: 'contain',
    margin:' 30px 0px',height:'350px'}"
            id="about_img"
          ></div>
     
          <div id="about_info" class="p-4 p-sm-0" v-html="testimonialObj.body"></div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12"></div>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>
<script>
import { watchEffect } from "vue";
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";

import GetOneTestimonial from "@/composables/GetOneTestimonial";
export default {
  name: "TestimonialView",
  components: {
    NavComponent,
    FooterComponent,
  },
  data() {
    return {
      testimonialObj: null,
      testimonialId: null,
      testimonial_error:null,
    };
  },
  computed: {},
  mounted() {
    this.testimonialId = JSON.parse(this.$route.query.id );
    const {  loadedTestimonial, testimonial_error, loadTestimonial } = GetOneTestimonial();
      watchEffect(() => {
        if (loadedTestimonial.value) {
          this.testimonialObj = loadedTestimonial.value;
          this.testimonial_error = null;
        }else{
          console.log(loadedTestimonial.value)
        }
        if (!this.isRefEmpty(testimonial_error)) {
          this.testimonial_error = testimonial_error.value;
        }
      });
      loadTestimonial(this.testimonialId)
  },
};
</script>

<style scoped>
iframe{
  width: 100%;
    height: 500px;
    margin: 60px 0px;
}
</style>
