import { ref } from 'vue'
import store from '@/store';
const GetLanguages = () => {

  const loadedLanguages = ref([])
  const lang_error = ref(null)

  const loadLanguages = async () => {
    try {
      let data = await fetch('https://dashboard.imslearn.org/api/languages')
      if (!data.ok) {
        throw Error('no available courses')
      }
       await data.json().then((res) => {
        if (res.success) {
          loadedLanguages.value = res.data
          store.commit('setLangs',  res.data);
        }else{
          console.log('unable to load data')
          console.log(res) // todo check with zeina what where is the error message in response res.error ?
        }
      })
    }
    catch (err) {
      lang_error.value = err.message
    }
  }

  return { loadedLanguages, lang_error, loadLanguages }
}

export default GetLanguages