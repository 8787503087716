<template>
  <section id="languages">
    <div class="container mt-5">
      <div v-if="titleObject">
        <h1>{{ titleObject.title }}</h1>
        <p>{{ titleObject.description }}<br /><br /></p>
      </div>
      <div v-else>
        <h1>Language</h1>
        <p>Available languages<br /><br /></p>
      </div>

      <div id="imageCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div
            v-for="(group, index) in groupedLanguages"
            :key="index"
            :class="{ 'carousel-item': true, active: index === 0 }"
          >
            <div class="row">
              <div
                v-for="(language, languageIndex) in group.languages"
                :key="languageIndex"
                class="col-12 col-md-3"
              >
                <div class="card">
                  <img
                    class="rounded-circle card-img-top w-100 d-block"
                    :src="language.image"
                    :alt="`image ${language.name}`"
                    @click="navigateToWithParam('lang_page', 'key', language.shortname)"
                    style="cursor: pointer;"
                  />
                  <div class="card-body">
                    <h5 class="card-title">{{ language.name }}</h5>
                    <p>{{ language.desc }}</p>
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="navigateToWithParam('lang_page', 'key', language.shortname)"
                      >
                      Enter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#imageCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#imageCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </section>

  <section id="languages_small_device">
    <div class="container mt-5">
      <div v-if="titleObject">
        <h1>{{ titleObject.title }}</h1>
        <p>{{ titleObject.description }}<br /><br />
        </p>
      </div>
      <div v-else>
        <h1>Language</h1>
        <p>Available languages<br /><br />
        </p>
      </div>
      <div id="SimageCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div
            v-for="(language, index) in langs"
            :key="index"
            :class="{ 'carousel-item': true, active: index === 0 }"
          >
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <img
                    class="rounded-circle card-img-top w-100 d-block"
                    :src="language.image"
                    :alt="`image ${language.name}`"
                    @click="navigateToWithParam('lang_page', 'key', language.shortname)"
                    style="cursor: pointer;"
                  />
                  <div class="card-body">
                    <h5 class="card-title">{{ language.name }}</h5>
                    <p>{{ language.desc }}<br /><br /></p>
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="navigateToWithParam('lang_page', 'key', language.shortname)"
                    >
                      Enter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#SimageCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span
          ><span class="visually-hidden">Previous</span></button
        ><button
          class="carousel-control-next"
          type="button"
          data-bs-target="#SimageCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span
          ><span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import GetLanguages from "@/composables/GetLanguages";
import { watchEffect } from "vue";
export default {
  name: "LanguagesComponent",
  components: {},
  props: {
    titles: [],
  },
  data() {
    return {
      langs: [],
      lang_error: null,
    };
  },
  computed: {
    groupedLanguages() {
      // Grouping the languages into sets of three for rendering the slider
      const result = [];
      if (this.langs.length) {
        for (let i = 0; i < this.langs.length; i += 3) {
          const chunk = this.langs.slice(i, i + 3);
          result.push({ languages: chunk });
        }
      }
      return result;
    },
    // Get the 'languages' object from the 'titles' array
    titleObject() {
      if (this.titles) {
        return this.titles.find((title) => title.name === "languages_section");
      }
      return null;
    },
  },
  mounted() {
    const { loadedLanguages, lang_error, loadLanguages } = GetLanguages();
    watchEffect(() => {
      if (!this.isEmpty(this.$store.getters.getLang)) {
        this.langs = this.$store.getters.getLang;
      }
      if (!this.isRefEmpty(lang_error)) {
        this.lang_error = lang_error.value;
      }
    });
    loadLanguages();
  },
};
</script>