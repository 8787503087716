<template>
  <section id="testimonials">
    <div class="container p-5">
      <h1>Testimonials</h1>
      <div
        id="testimonialCarousel"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            v-for="(group, index) in groupedTestimonials"
            :key="index"
            class="carousel-item"
            :class="{ active: index === 0 }"
          >
            <div class="row">
              <div
                v-for="(testimonial, innerIndex) in group.testimonials"
                :key="innerIndex"
                class="col-md-6 sigular_slide"
              >
                <div class="card">
                  <div class="row">

                    <div class="col-md-2 test_profile" :style="{ 'background-image': 'url(' + testimonial.image + ')' }"></div>
                    <div class="col-md-10">
                      <div class="card-body">
                        <p class="country">{{ testimonial.country }}</p>
                        <h5 class="card-title">{{ testimonial.name }}</h5>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="card-body">
                        <p>
                          {{ testimonial.info }}
                        </p>
                        <!-- <button class="btn btn-primary" type="button" @click="navigateToWithParam('single_testimonial','id',testimonial.id)">
                          Read More
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span
          ><span class="visually-hidden">Previous</span></button
        ><button
          class="carousel-control-next"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span
          ><span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </section>

  <section id="testimonials_small_device">
    <div class="container p-5">
      <h1>Testimonials</h1>
      <div
        id="StestimonialCarousel"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            v-for="(testimonial, index) in loadedTestimonials"
            :key="index"
            class="carousel-item"
            :class="{ active: index === 0 }"
          >
            <div class="row">
              <div class="col-12 sigular_slide">
                <div class="card">
                  <div class="row">
                    <!-- <div class="col-md-2">
                      <img
                        class="rounded-circle card-img-top"
                        :alt="testimonial.name"
                        :src="testimonial.image"
                      />
                    </div> -->
                    <div class="col-md-2 test_profile" :style="{ 'background-image': 'url(' + testimonial.image + ')' }"></div>

                    <div class="col-md-10">
                      <div class="card-body">
                        <p class="country">{{ testimonial.country }}</p>
                        <h5 class="card-title">{{ testimonial.name }}</h5>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="card-body">
                        <p>
                          {{ testimonial.info }}
                        </p>
                        <!-- <button class="btn btn-primary" type="button" @click="navigateToWithParam('single_testimonial','id',testimonial.id)">
                          Read More
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#StestimonialCarousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span></button
        ><button
          class="carousel-control-next"
          type="button"
          data-bs-target="#StestimonialCarousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span
          ><span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </section>
</template>

  <script>
import Gettestimonials from "@/composables/GetTestimonials";
import { watchEffect } from "vue";
export default {
  name: "TestimonialsComponent",
  components: {},
  props: {
    testimonials: [],
  },
  data() {
    return {
      loadedTestimonials: [],
      testimonials_error: null,
    };
  },
  mounted() {
    const { loadedTestimonials, testimonials_error, loadTestimonials } =
      Gettestimonials();
    watchEffect(() => {
      if (loadedTestimonials && loadedTestimonials.value) {
        this.loadedTestimonials = loadedTestimonials.value;
      }
      if (testimonials_error) {
        this.testimonials_error = testimonials_error.value;
      }
    });
    loadTestimonials();
  },
  computed: {
    groupedTestimonials() {
      const result = [];
      if (this.loadedTestimonials.length) {
        for (let i = 0; i < this.loadedTestimonials.length; i += 2) {
          const chunk = this.loadedTestimonials.slice(i, i + 2);
          result.push({ testimonials: chunk });
        }
      }
      return result;
    },
  },
};
</script>