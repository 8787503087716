<template>
  <div class="col">
    
    <div class="card-container">
      <div class="card">
        <img
          class="card-img-top w-100 d-block fit-cover"
          :src="getThumbnailUrl(video.link)"
          :alt="video.title"
        />
        <div class="card-body p-4">
      

          <h4 class="card-title">{{ video.title }}</h4>
          <div class="d-flex">
            <button class="btn btn-primary float-end" type="button" @click="navigateToWithParam('single_video','id',video.id)">
              <strong>Watch</strong>
            </button>
          </div>
        </div>
      </div>
  
    </div>
  
 </div>
</template>
<script>
export default {
  name: "VideoCell",
  props: {
    video: {},
  },
  components: {},
  mounted() {
  },
  methods: {
   
  },
};
</script>